import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";

interface OnBoardMapProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
}

export const BoardBox = styled.div`
    display: flex;
    align-items: baseline;
`;

export const BoardTitle = styled.div`
    width: 60px;
    span {
        display: inline-block;
        border-radius: 30px;
        background-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.white};
        padding: 4px 8px;
        font-weight: bold;
        line-height: 1.1;
    }
`;
export const StudentList = styled.ul`
    margin-bottom: 10px;
    font-weight: bold;
`;

interface StudentProps {
    thisStudentId: number | undefined;
    studentId: number | undefined;
}
export const Student = styled.li<StudentProps>`
    margin-bottom: 12px;
    line-height: 1;
    :last-child {
        margin-bottom: 0;
    }
    ${({ studentId, thisStudentId }) =>
        studentId &&
        Number(thisStudentId) !== studentId &&
        css`
            color: #dedede;
            a,
            span {
                color: #dedede !important;
            }
        `};
    span {
        line-height: 1.8;
        font-weight: bold;
    }
`;

export const NoShow = styled.span`
    color: ${({ theme }) => theme.colors.orange};
    margin-left: 4px;
`;

// 학생 정보 - 학생 이름 관련 렌더링
const renderName = (schedule: any, isOurStudent: boolean) => {
    // B2C 학생일 경우
    if (schedule.isB2CUser) return <span>{schedule.student?.name}(B2C)</span>;
    // 우리 학생일 경우
    if (isOurStudent) {
        return (
            <Link to={`/students/${schedule.student?.id}`} title="학생 페이지로 이동" target="blank">
                {schedule.student?.name}
            </Link>
        );
    }
    // 그 외
    return <span>{schedule.student?.name}</span>;
};

// 학생 정보 - 탑승 여부 관련 렌더링
const renderBoardSwitch = (schedule: any) => {
    if (schedule.boardSwitch !== 1) return null;
    if (schedule.status === 3) return <NoShow>미승차</NoShow>;
    if (schedule.status === 4) return " 미탑승예약";
    return ` ${schedule.timeOffBoard}`;
};

export const OnBoardMap: React.FC<OnBoardMapProps> = ({ stationId, schedules, studentId }) => {
    let onBoardCounts = 0;

    if (!_.isEmpty(schedules)) {
        schedules.forEach((schedule: any) => {
            if (stationId === Number(schedule.pickUpStation?.id) && schedule.status !== 4) {
                onBoardCounts += 1;
            }
        });
    }

    // 승차인원이 없을 경우 빈 태그 반환
    if (onBoardCounts === 0) return <></>;

    return (
        <BoardBox>
            <BoardTitle>
                <span>승차</span>
            </BoardTitle>
            <StudentList>
                {schedules.map((schedule: any, index: number) => {
                    // 현재 선택된 학생이 다른 학원 학생인지 여부
                    const isOurStudent: boolean = !!(schedule?.student?.isOtherAcademyStudent) === false;
                    // 넘어온 stationId와 schedule.pickUpStation.id가 같고, status가 4가 아닐 때만 렌더링
                    const studentInfoRenderCondition = ((stationId === Number(schedule.pickUpStation?.id)) && (schedule.status !== 4));

                    // 렌더링 조건이 아닐 경우 빈 태그 반환
                    if (!studentInfoRenderCondition) return <React.Fragment key={index} />;

                    // 학생 정보 렌더링
                    return (
                        <Student key={schedule.student?.id} thisStudentId={schedule.student?.id} studentId={studentId}>
                            {renderName(schedule, isOurStudent)}
                            {schedule.boardSwitch === 1 ? " (타요)" : " (안타요)"}
                            {renderBoardSwitch(schedule)}
                        </Student>
                    );
                })}
            </StudentList>
        </BoardBox>
    );
};
