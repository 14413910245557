import {AxiosEnum} from "enum/AxiosEnum";
import axios, {Method} from "axios";

const token = process.env.REACT_APP_ADMIN1_API_TOKEN;

export const getBoardRequestCategories = async () => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequestCategories.php`;

    const config = {
        method: AxiosEnum.GET.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data.data;
};

export type GetRequestsParams = {
    academyId: number;
    page?: number;
    title?: string;
    writer?: string;
    status?: number;
};

export const getRequests = async (data: GetRequestsParams) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequests.php`;

    const config = {
        method: AxiosEnum.GET.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        params: {
            academyId: data.academyId,
            page: data.page,
            title: data.title,
            writer: data.writer,
            status: data.status,
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data.data;
};

export const getRequest = async (id: number) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequest.php`;

    const config = {
        method: AxiosEnum.GET.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        params: {
            id,
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data.data;
};

export type PostRequestParams = {
    academyId: number;
    writerId: number;
    requestCategoryId: number;
    title: string;
    content: string;
};

export const postRequest = async (data: PostRequestParams) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequest.php`;
    console.log("APIdata", data);
    const config = {
        method: AxiosEnum.POST.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        data: {
            academyId: Number(data.academyId),
            writerId: Number(data.writerId),
            requestCategoryId: Number(data.requestCategoryId),
            title: data.title,
            content: data.content,
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data;
};

export type DelRequestParams = {
    requestId: number;
    userId: number;
    userType: number;
};

export const delRequest = async (data: DelRequestParams) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequest.php`;
    console.log("APIdata", data);
    const config = {
        method: AxiosEnum.DELETE.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        data: {
            requestId: Number(data.requestId),
            userId: Number(data.userId),
            userType: Number(data.userType),
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data;
};
// export type PutBoardParams = {
//     requestID: number;
//     academyID: number;
//     writerID: number;
//     requestCategoryID: number;
//     title: string;
//     content: string;
//     userType: string;
// };
//
// export async function putBoard(data: PutBoardParams) {
//     const url = process.env.REACT_APP_ADMIN1_API_HOST;
//
//     const config = {
//         method: AxiosEnum.PUT.value as Method,
//         url: url,
//         body: {
//             requestId: data.requestID,
//             academyId: data.academyID,
//             writerId: data.writerID,
//             requestCategoryId: data.requestCategoryID,
//             title: data.title,
//             content: data.content,
//             userType: "academy",
//         },
//     };
//
//     const axiosResponse = await axios(config);
//
//     return axiosResponse.status;
// }
//
// export type DeleteBoardParams = {
//     requests: number;
//     writerID: number;
//     userType: string;
// };
//
// export async function deleteBoard(data: DeleteBoardParams) {
//     const url = process.env.REACT_APP_ADMIN1_API_HOST;
//
//     const config = {
//         method: AxiosEnum.DELETE.value as Method,
//         url: url,
//         body: {
//             writerId: data.writerID,
//             requests: data.requests,
//             userType: "academy",
//         },
//     };
//
//     const axiosResponse = await axios(config);
//
//     return axiosResponse.status;
// }

export type GetRequestsRepliesParams = {
    boardRequestId: number;
};

export const getRequestReplies = async (data: GetRequestsRepliesParams) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequestReplies.php`;

    const config = {
        method: AxiosEnum.GET.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        params: {
            boardRequestId: data.boardRequestId,
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data.data;
};

export type PostReplyParams = {
    boardRequestId: number;
    userType: number;
    createdBy: number;
    content: string;
};

export const postReply = async (data: PostReplyParams) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequestReply.php`;
    console.log("APIdata", data);
    const config = {
        method: AxiosEnum.POST.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        data: {
            boardRequestId: Number(data.boardRequestId),
            userType: Number(data.userType),
            createdBy: Number(data.createdBy),
            content: data.content,
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data;
};

export type DelReplyParams = {
    boardRequestId: number;
    boardRequestReplyId: number;
    userType: number;
    createdBy: number;
    currentReply: string;
};

export const delReply = async (data: DelReplyParams) => {
    const url = `${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequestReply.php`;
    console.log("APIdata", data);
    const config = {
        method: AxiosEnum.DELETE.value as Method,
        url: url,
        headers: {Authorization: `Bearer ${token}`},
        data: {
            boardRequestId: Number(data.boardRequestId),
            boardRequestReplyId: Number(data.boardRequestReplyId),
            userType: Number(data.userType),
            createdBy: Number(data.createdBy),
            currentReply: data.currentReply,
        },
    };

    const axiosResponse = await axios(config);

    return axiosResponse.data;
};

export const uploadFileApi = async (formData: FormData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequestFiles.php`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        return result;
    } catch (error) {
        console.error("Error uploading file:", error);
        document.getElementById("response").innerHTML = `<p>Error: ${error.message}</p>`;
    }
};

export const deleteFileApi = async (fileKey) => {
    fetch(`${process.env.REACT_APP_ADMIN1_API_HOST}/board/request/boardRequestFiles.php`, {
        method: "DELETE", // HTTP 메서드
        headers: {
            "Content-Type": "application/json", // JSON 형식으로 데이터 전송
        },
        body: JSON.stringify({key: fileKey}), // 요청 본문에 파일 키 포함
    })
        .then((response) => response.json()) // JSON 응답 처리
        .then((data) => {
            if (data.success) {
                console.log("파일 삭제 성공:", data.message);
            } else {
                console.error("파일 삭제 실패:", data.message);
            }
        })
        .catch((error) => {
            console.error("요청 중 에러 발생:", error);
        });
};

export const getFileInfo = async (fileText: any) => {
    const fileTextDiv = document.createElement("div");
    fileTextDiv.innerHTML = fileText;

    const fileName = fileTextDiv.querySelector("img") !== null ? fileTextDiv.querySelector("img").alt : fileTextDiv.querySelector("a").innerHTML;
    const fileKey = `${fileText.split("/")[3]}/${fileName}`;

    return {fileName, fileKey};
};
