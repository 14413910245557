import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    ButtonWrapper,
    EditBoardBox,
    EditStudentContainer,
    EditStudentWrapper,
    EditStudentSpan,
    BoardTitle,
    BoardBox,
    StudentWrapper,
    Student,
    Block,
    Name,
    MoveImg,
    DeleteImg,
} from "./EditOnBoard";
import _ from "lodash";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";
import { DeleteButton, DragIcon, PlusIcon } from "assets/images";
import { StudentPopUp } from "components/layout/StudentPopUp";
import { StudentList } from "./Modal/StudentList";
import { Draggable } from "react-beautiful-dnd";

type OverBookingDataProps = {
    year?: string;
    seatOccupancyRates?: any[];
};
interface OffBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
    busType?: string;
    enrolledStudentList?: any;
    pickUpStationId?: number | string;
    isFirst?: any;
    edit?: any;
    overBookingWarning?: boolean;
    setOverBookingWarning?: React.Dispatch<React.SetStateAction<any>>;
    setDuplicateWarning?: React.Dispatch<React.SetStateAction<any>>;
    setEdit?: React.Dispatch<React.SetStateAction<any>>;
    overBookingData?: OverBookingDataProps[];
    duplicateData?: string[];
    detailsIds?: number[];
}

const renderName = (student: any, isOurStudent: boolean) => {
    const nickNamePart = student?.nickName && student?.nickName.trim() !== "" && student?.nickName !== "-"
        ? `${student?.nickName} / `
        : "";
    const nameText = `${nickNamePart}${student?.name}(${student.parentPhone.slice(-4)})`;

    if (isOurStudent) {
        return (
            <Name
                to={`/students/${student?.id}`}
                title={"학생 페이지로 이동"}
                target={"blank"}
            >
                {nameText}
            </Name>
        );
    }
    return <p>{nameText}</p>;
};

export const EditOffBoard: React.FC<OffBoardProps> = ({
    stationId,
    schedules,
    studentId,
    busType,
    enrolledStudentList,
    pickUpStationId,
    edit,
    isFirst,
    overBookingWarning,
    setEdit,
    overBookingData,
    setOverBookingWarning,
    setDuplicateWarning,
    duplicateData,
    detailsIds,
}) => {
    const [showPeriod, setShowPeriod] = useState(false);
    const [studentName, setStudentName] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    let offBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.forEach((schedule: any) => {
            if (stationId === Number(schedule?.takeOffStationId)) {
                offBoardCounts += 1;
            }
        });
    }

    const handleMouseEnter = (name: string) => () => {
        setShowPeriod(true);
        setStudentName(name);
    };

    const handleMouseLeave = () => {
        setShowPeriod(false);
        setStudentName("");
    };

    const showStudentList = () => {
        setShowModal(true);
    };

    const keyToExtract = "id";
    let duplicateId = duplicateData?.map((item) => item[keyToExtract]);

    const handleDeleteStudent = (id: number) => {
        setEdit(edit.filter((item: any) => item?.id !== id));
    };
    useEffect(() => {
        for (const obj of overBookingData) {
            for (const item of obj?.seatOccupancyRates) {
                if (
                    enrolledStudentList?.length + edit?.length <=
                    item?.totalSeat
                ) {
                    setOverBookingWarning(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit]);

    useEffect(() => {
        const warningCnt = edit
            .filter((item: any) => duplicateId.includes(item.id))
            .map((item: any) => item.id);

        if (warningCnt?.length === 0) {
            setDuplicateWarning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicateId]);

    useEffect(() => {
        const filteredEdit = edit.filter(
            (item: any) => !detailsIds.includes(item.id)
        );
        setEdit(filteredEdit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsIds]);

    return (
        <>
            {busType === "하원" && !isFirst && (
                <ButtonWrapper border={schedules?.length}>
                    {edit?.filter((item) => item.takeOffStationId === stationId)
                        ?.length > 0 && (
                            <EditBoardBox>
                                <OffBoardTitle style={{ top: "6px" }}>
                                    <span>하차</span>
                                </OffBoardTitle>
                                <EditStudentContainer>
                                    {edit?.map((editStudent: any, idx: number) => {
                                        if (
                                            stationId ===
                                            Number(editStudent?.takeOffStationId)
                                        ) {
                                            return (
                                                <Draggable
                                                    key={
                                                        "outbound-draggable-" +
                                                        editStudent.id
                                                    }
                                                    draggableId={
                                                        "outbound-" + editStudent.id
                                                    }
                                                    data-studentId={editStudent.id}
                                                    index={idx}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}
                                                        >
                                                            <EditStudentWrapper
                                                                key={`detail - ${editStudent?.id}`}
                                                                overBookingWarning={
                                                                    overBookingWarning
                                                                }
                                                                duplicateId={
                                                                    duplicateId
                                                                }
                                                                studentId={
                                                                    editStudent?.id
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <MoveImg
                                                                        src={
                                                                            DragIcon
                                                                        }
                                                                        alt="drag"
                                                                    />
                                                                    <EditStudentSpan>
                                                                        {editStudent?.nickName ===
                                                                            "" ||
                                                                            editStudent?.nickName ===
                                                                            "-"
                                                                            ? ""
                                                                            : `${editStudent?.nickName} / `}
                                                                        {`${editStudent?.name
                                                                            }(${editStudent?.parentPhone.slice(
                                                                                -4
                                                                            )})`}
                                                                    </EditStudentSpan>
                                                                </div>
                                                                <DeleteImg
                                                                    src={
                                                                        DeleteButton
                                                                    }
                                                                    alt="delete"
                                                                    onClick={() =>
                                                                        handleDeleteStudent(
                                                                            editStudent?.id
                                                                        )
                                                                    }
                                                                />
                                                            </EditStudentWrapper>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                    })}
                                </EditStudentContainer>
                            </EditBoardBox>
                        )}
                    <AddButton onClick={() => showStudentList()}>
                        <AddImg />
                        <AddText>학생 추가</AddText>
                    </AddButton>
                </ButtonWrapper>
            )}
            {busType === "등원" &&
                edit?.length > 0 &&
                stationId === edit[0]?.takeOffStationId && (
                    <ButtonWrapper border={offBoardCounts}>
                        <EditBoardBox>
                            <OffBoardTitle style={{ top: "6px" }}>
                                <span>하차</span>
                            </OffBoardTitle>
                            <EditStudentContainer>
                                {edit?.map((item: any) => {
                                    return (
                                        <EditStudentWrapper
                                            key={item?.id}
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <MoveImg
                                                    src={DragIcon}
                                                    alt="drag"
                                                />
                                                <EditStudentSpan>
                                                    {item?.nickName === "" ||
                                                        item?.nickName === "-"
                                                        ? ""
                                                        : `${item?.nickName} / `}
                                                    {`${item?.name
                                                        }(${item?.parentPhone.slice(
                                                            -4
                                                        )})`}
                                                </EditStudentSpan>
                                            </div>
                                            <DeleteImg
                                                src={DeleteButton}
                                                alt="delete"
                                            />
                                            <Block />
                                        </EditStudentWrapper>
                                    );
                                })}
                            </EditStudentContainer>
                        </EditBoardBox>
                    </ButtonWrapper>
                )}

            {offBoardCounts > 0 && (
                <BoardBox
                    style={{
                        borderRadius:
                            busType === "하원"
                                ? "0px 0px 8px 8px"
                                : edit?.length > 0
                                    ? "0px 0px 8px 8px"
                                    : "8px",
                        borderTop:
                            busType === "하원" || edit?.length > 0
                                ? "none"
                                : "1px solid #e7e7e7",
                    }}
                >
                    <OffBoardTitle>
                        <span>하차</span>
                    </OffBoardTitle>
                    <StudentWrapper>
                        {schedules?.map((student: any, index: any) => {
                            // 다른 학원 학생 여부 판단
                            const isOurStudent: boolean = !(student?.isOtherAcademyStudent);
                            // 특정 조건이 만족하지 않으면 렌더링하지 않음
                            const studentInfoRenderCondition = stationId === Number(student.takeOffStationId);
                            if (!studentInfoRenderCondition) return <React.Fragment key={index} />;
                            return (
                                <Student
                                    key={student.id}
                                    thisStudentId={student?.id}
                                    studentId={studentId}
                                    onMouseEnter={handleMouseEnter(student?.name)}
                                    onMouseLeave={handleMouseLeave}>
                                    {renderName(student, isOurStudent)}
                                    <div style={{ position: "relative" }}>
                                        {showPeriod && (studentName === student?.name) && (
                                            <>
                                                <ToolTipBoxVector />
                                                <ToolTip>
                                                    <PeriodSpan>
                                                        승차 일자 :
                                                    </PeriodSpan>
                                                    <br />
                                                    {student?.tickets?.map((item: any, idx: number) => {
                                                        return (
                                                            <PeriodSpan key={idx}>
                                                                {item}
                                                            </PeriodSpan>
                                                        );
                                                    })}
                                                </ToolTip>
                                            </>
                                        )}
                                    </div>
                                </Student>
                            );
                        })}
                    </StudentWrapper>
                </BoardBox>
            )}
            {
                showModal && (
                    <StudentPopUp
                        id={showModal}
                        handler={setShowModal}
                        contents={
                            <StudentList
                                onClose={setShowModal}
                                enrolledStudentList={enrolledStudentList}
                                pickUpStationId={pickUpStationId}
                                takeOffStationId={stationId}
                                edit={edit}
                                setEdit={setEdit}
                            />
                        }
                    />
                )
            }
        </>
    );
};

const OffBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.blue};
    }
`;

const ToolTip = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    flex-direction: column;
    position: absolute;
    width: 163px;
    padding: 12px;
    border-radius: 4px;
    background: #333;
    top: 8px;
    left: -35px;
    z-index: 9999999999;
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 3px;
    left: 40px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;

const PeriodSpan = styled.span`
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
`;

const AddButton = styled.button`
    width: 83px;
    height: 24px;
    display: flex;
    padding: 3px 8px;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    background: #ffd100;
    border: none;
`;

const AddImg = styled.div`
    background: url(${PlusIcon}) center center no-repeat;
    width: 18px;
    height: 19px;
`;

const AddText = styled.span`
    font-family: NanumSquareRound;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
`;
