import React, {useCallback, useEffect, useContext, useRef, useState} from "react";
import {GlobalContext} from "context/global";
import {isEmptyArray} from "components/ValidataionUtil";
import SVGImage from "components/common/SVGImage";
import styled from "styled-components";
import {flexColumn, flexRow} from "styles/CommonStyle";
import {media} from "styles/media";
import {postReply, delReply, uploadFileApi, deleteFileApi, getFileInfo} from "service/board/Board";

// image
import AcademyIcon from "assets/images/icons/academy_info_icon.svg";
import YBIcon from "assets/images/icons/academy_yb_logo.svg";
import {getRequestReplies} from "../../../service/board/Board";
import {PopUp2} from "components/layout/PopUp2";
import DeleteReplyConfirmPopUp from "../popUp/DeleteReplyConfirmPopUp";

const minHeight = 20;
const maxHeight = 200;

type DetailReplies = {requestId: number};
type deleteReplyDataType = {
    boardRequestId: number;
    boardRequestReplyId: number;
    userType: number;
    createdBy: number;
    currentReply: string;
};

function BoardDetailReplies(props: DetailReplies) {
    const textareaRef = useRef(null);
    const inputFileRef = useRef(null);
    const [inputHeight, setInputHeight] = useState(20);
    const [user] = useState(JSON.parse(localStorage.getItem("userInfo")));
    const [replies, setReplies] = useState([]);
    const [newReply, setNewReply] = useState<string>("");
    const {setToast} = useContext(GlobalContext);
    const [deleteReplyData, setDeleteReplyData] = useState<deleteReplyDataType>(null);
    const [deleteReplyPopUp, setDeleteReplyPopUp] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null); // State for selected file
    const [uploadFileResponse, setUploadFileResponse] = useState<string>(""); // State for selected file

    const getReplies = useCallback(() => {
        const data = {
            boardRequestId: Number(props.requestId),
        };
        getRequestReplies(data)
            .then((res) => {
                console.log(res);
                setReplies(res.boardRequestReplies);
            })
            .catch((error) => {
                throw new Error(`getRequestReplies() failed. error : ${error}`);
            });
    }, [props.requestId]);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            // 초기 높이 설정
            textarea.style.height = `${minHeight}px`;
            textarea.style.overflowY = "hidden";
        }
    }, []);

    const handleInput = useCallback(
        (e) => {
            const textarea = e.target;
            textarea.style.height = `${minHeight}px`;

            const scrollHeight = textarea.scrollHeight;

            setInputHeight(scrollHeight);

            if (scrollHeight > minHeight) {
                if (scrollHeight < maxHeight) {
                    textarea.style.height = `${scrollHeight}px`;
                } else {
                    textarea.style.height = `${maxHeight}px`;
                    textarea.style.overflowY = "auto";
                }
            }
            setNewReply(textarea.value);
        },
        [setInputHeight]
    );

    const createReply = useCallback(
        (customContent?: string) => {
            const replyContent = customContent || newReply;

            const newReplyData = {
                boardRequestId: Number(props.requestId),
                userType: 2,
                createdBy: Number(user.id),
                content: replyContent,
            };

            postReply(newReplyData)
                .then((res) => {
                    console.log(res);
                    if (res.status === -1) {
                        console.log(`${res.error.message}`);
                        setToast({
                            toggle: true,
                            toastStatus: "fail",
                            message: `댓글 등록에 실패했습니다.`,
                        });
                    } else {
                        getReplies();
                        setSelectedFile(null);
                        const inputFile = inputFileRef.current;
                        inputFile.value = "";
                        setNewReply("");
                        const textarea = textareaRef.current;
                        if (textarea) {
                            textarea.value = "";
                        }
                        setToast({
                            toggle: true,
                            toastStatus: "success",
                            message: `댓글을 등록하였습니다.`,
                        });
                    }
                })
                .catch((error) => {
                    throw new Error(`Create Reply failed. error : ${error}`);
                });
        },
        [props.requestId, user.id, newReply, getReplies, setToast, setNewReply, setSelectedFile]
    );

    const deleteReply = useCallback(async () => {
        console.log(deleteReplyData);
        let currentReply = deleteReplyData.currentReply;
        const currentReplyDiv = document.createElement("div");
        currentReplyDiv.innerHTML = currentReply;

        let fileName;
        let fileKey;

        if (currentReply.indexOf("cloudfront.net") !== -1) {
            const fileInfo = await getFileInfo(currentReply);
            console.log(fileInfo);

            fileName = fileInfo.fileName;
            fileKey = fileInfo.fileKey;
        }

        delReply(deleteReplyData)
            .then(async (res) => {
                console.log(res);
                if (res.status === -1) {
                    alert("댓글 삭제에 실패했습니다.");
                    console.log(`${res.error.message}`);
                } else {
                    if (fileName !== undefined) {
                        const response = await deleteFileApi(fileKey);
                        console.log(response);
                    }

                    setDeleteReplyPopUp(false);
                    const target = document.getElementById(`reply-${deleteReplyData.boardRequestReplyId}`);
                    target.style.display = "none";
                    setToast({
                        toggle: true,
                        toastStatus: "success",
                        message: `댓글을 삭제하였습니다.`,
                    });
                    getReplies();
                }
            })
            .catch((error) => {
                throw new Error(`Delete Reply failed. error : ${error}`);
            });
    }, [setToast, deleteReplyData, getReplies]);

    useEffect(() => {
        getReplies();
    }, [getReplies]);

    const handleRefresh = useCallback(() => {
        getReplies();
    }, [getReplies]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0]; // 선택한 파일
        const limitSizeMB = 10;
        const maxSize = limitSizeMB * 1024 * 1024; // 5MB
        if (file && file.size > maxSize) {
            setSelectedFile(null);
            setUploadFileResponse(`파일 크기는 ${limitSizeMB}MB를 초과할 수 없습니다.`);
            event.target.value = "";
            return;
        } else {
            setUploadFileResponse(``);
        }

        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const uploadFile = useCallback(async () => {
        if (!selectedFile) {
            alert("파일을 선택해주세요.");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const res = await uploadFileApi(formData);
            const urlParts = res.url.split("/");
            const fileName = urlParts[urlParts.length - 1]; // URL의 마지막 부분이 파일 이름
            let replyText;

            if (res.url.match(/\.(jpeg|jpg|png|gif|bmp)$/i)) {
                replyText = `<a href="${res.url}" title="Click to download" download><img src="${res.url}" alt="${fileName}" style="max-width: 100%; height: auto;" /></a>`;
                createReply(replyText);
            } else {
                replyText = `<a href="${res.url}" title="Click to download" download>${fileName}</a>`;
                createReply(replyText);
            }

            // 기존 createReply 함수 재사용
            // createReply(replyText);
        } catch (error) {
            setUploadFileResponse(error.message);
            console.error("File upload failed:", error);
        }
    }, [selectedFile, createReply]);

    return (
        <BoardDetailRepliesWrapper>
            <Container>
                <Category>
                    {`댓글 ${replies.length}`} <RefreshButton onClick={handleRefresh}>새로고침</RefreshButton>
                </Category>
                <RepliesContainer>
                    {isEmptyArray(replies) ? (
                        <EmptyReplies>등록된 댓글이 없습니다.</EmptyReplies>
                    ) : (
                        replies.map((c, idx) => {
                            return (
                                <RepliesWrapper key={idx} id={`reply-${c.id}`}>
                                    <RepliesInfo>
                                        <CreatedAt>
                                            <AcademyImg source={c.writer.userType.id === 2 ? AcademyIcon : YBIcon} />
                                            {c.writer.userType.id === 2 ? c.writer.name : "YellowBus"}
                                            <Line />
                                            {c.updatedAt !== null ? `${c.updatedAt} (edited)` : c.createdAt}
                                        </CreatedAt>
                                        {c.writer.userType.id === 2 && c.writer.id === user.id ? (
                                            <ButtonWrapper>
                                                <RepliesDeleteButton
                                                    onClick={() => {
                                                        // deleteReply(c.id, c.content);
                                                        setDeleteReplyData({
                                                            boardRequestId: Number(props.requestId),
                                                            boardRequestReplyId: Number(c.id),
                                                            userType: 2,
                                                            createdBy: Number(user.id),
                                                            currentReply: c.content,
                                                        });
                                                        setDeleteReplyPopUp(true);
                                                    }}
                                                >
                                                    삭제
                                                </RepliesDeleteButton>

                                                {/* <Line />
                                                <RepliesModifyButton>수정</RepliesModifyButton> */}
                                            </ButtonWrapper>
                                        ) : (
                                            ""
                                        )}
                                    </RepliesInfo>
                                    <Replies dangerouslySetInnerHTML={{__html: c.content}}></Replies>
                                </RepliesWrapper>
                            );
                        })
                    )}
                    {deleteReplyPopUp && (
                        <PopUp2
                            open={deleteReplyPopUp}
                            handler={setDeleteReplyPopUp}
                            contents={<DeleteReplyConfirmPopUp onClose={() => setDeleteReplyPopUp(false)} deleteReply={deleteReply} />}
                            width="360px"
                            height="200px"
                        />
                    )}
                </RepliesContainer>
            </Container>
            <InputContainer>
                <FileInput type="file" onChange={handleFileChange} ref={inputFileRef} />
                <UploadButton onClick={uploadFile}>파일 등록</UploadButton>
                <UploadFileResponse id="response" hasResponse={uploadFileResponse}>
                    {uploadFileResponse}
                </UploadFileResponse>
            </InputContainer>
            <TextAreaWrapper>
                <WebTextArea inputHeight={inputHeight} onInput={handleInput} ref={textareaRef} placeholder="댓글 입력 후 등록버튼을 클릭해주세요." />
                <InputButton
                    inputHeight={inputHeight}
                    onClick={(e) => {
                        if (newReply !== "") {
                            createReply();
                        }
                    }}
                >
                    댓글 등록
                </InputButton>
            </TextAreaWrapper>
        </BoardDetailRepliesWrapper>
    );
}

export default BoardDetailReplies;

const BoardDetailRepliesWrapper = styled.div`
    height: 100%;
    width: 100%;

    ${media.tablet} {
        position: relative;
    }
`;

const Container = styled.div`
    ${flexColumn}
    padding: 0 20px;
    height: 100%;
    width: 100%;

    ${media.tablet} {
        padding: 0 0 0 40px;
        overflow: scroll;
    }
`;

type InputHeightProps = {
    inputHeight: number;
};

const RepliesContainer = styled.div`
    ${flexColumn};

    ${media.tablet} {
        overflow: scroll;

        ::-webkit-scrollbar {
            width: 10px;
            height: 100%;
            border-radius: 30px;
            background: #fff;
        }

        ::-webkit-scrollbar-thumb {
            background: #ffeb9f;
            border-radius: 30px;
            height: 30%;
        }
    }
`;

const RepliesWrapper = styled.div`
    ${flexColumn};
    &:hover {
        opacity: 0.8;
    }
`;

const RepliesInfo = styled.div`
    ${flexRow};
    align-items: center;
    justify-content: space-between;
    ${media.tablet} {
        justify-content: flex-start;
    }
`;

const Replies = styled.div`
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 160%; /* 19.6px */
    margin-top: 4px;
    margin-bottom: 16px;
    padding-left: 20px;
    white-space: pre-wrap;

    & a {
        text-decoration: underline;
    }

    & img {
        max-height: 200px;
    }
`;

const CreatedAt = styled.div`
    ${flexRow};
    align-items: center;
    color: #585858;
    font-size: 11px;
    font-weight: 400;
    line-height: 140%; /* 15.4px */
    column-gap: 4px;
`;

const Category = styled.div`
    color: #174490;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 0 12px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 20px;
`;

const EmptyReplies = styled.div`
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.36px;
    margin: 10px auto 30px;
`;

const TextAreaWrapper = styled.div`
    ${flexRow};
    justify-content: space-between;
    background: #fff;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border: none;
    border-radius: 0 0 30px 30px;
    padding: 18px 10px;
    // border-top: 1px solid #e7e7e7;
    ${media.tablet} {
        padding: 18px 30px;
    }
`;

const WebTextArea = styled.textarea<InputHeightProps>`
    width: calc(100% - 100px);
    min-height: 24px;
    height: ${(props) => props.inputHeight && props.inputHeight}px;
    line-height: 160%; /* 19.6px */
    font-size: 14px;
    border: none;
    resize: none;
    border-right: 1px solid #e7e7e7;

    :focus {
        outline: none;
    }

    &::placeholder {
        color: #cecece;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
`;

const InputButton = styled.div<InputHeightProps>`
    flex-basis: 90px;
    display: flex;
    justify-content: center;
    color: #174490;
    line-height: 20px;
    cursor: pointer;
    font-size: 12px;
    ${media.tablet} {
    }
`;

const AcademyImg = styled(SVGImage)`
    width: 16px;
    height: 16px;
`;

const Line = styled.div`
    width: 1px;
    height: 15px;
    background: #e7e7e7;
    margin: 0 4px;
`;

const ButtonWrapper = styled.div`
    ${flexRow};
    align-items: center;
    column-gap: 5px;

    ${media.tablet} {
        margin-left: 20px;
    }
`;

const RepliesDeleteButton = styled.div`
    color: #585858;
    font-size: 11px;
    font-weight: 400;
    line-height: 140%; /* 15.4px */
    cursor: pointer;
`;

const RepliesModifyButton = styled(RepliesDeleteButton)`
    color: #174490;
`;

const RefreshButton = styled.button`
    background: none;
    border: none;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
    color: #999;
    &:hover {
        color: #000;
    }
`;

// Styled components
export const InputContainer = styled.div`
    padding: 10px 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;
    ${media.tablet} {
        padding: 10px 30px;
    }
`;

export const FileInput = styled.input`
    padding: 10px;
    height: auto;
    border-color: #eee;
    width: calc(100% - 100px);
`;

export const UploadButton = styled.button`
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 90px;
    display: inline-block;
    font-size: 12px;

    &:hover {
        background-color: #ddd;
    }
`;

type ResponseProps = {
    hasResponse: string;
};

export const UploadFileResponse = styled.div<ResponseProps>`
    width: 100%;
    color: #f39c12;
    margin-top: ${(props) => (props.hasResponse !== "" ? "10px" : "0px")};
`;
