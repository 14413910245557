import React, { useContext, useState } from "react";
import { styled } from "styles/theme";
import ArrowDown from "assets/images/icons/arrow_down_icon_grey.svg";
import SelectCheck from "assets/images/icons/check_icon_yellow.svg";
import { css } from "styles/theme-components";
import { media } from "styles/media";
import { PopUp2 } from "components/layout/PopUp2";
import { ConfirmBoardSwitch } from "./ConfirmBoardSwitch";
import { BusServiceContext } from "context/busService";

interface BoardSwitchProps {
    schedule: any;
    boardType: string;
}

const BoardSwitchWrap = styled.div`
    display: inline-block;
    position: relative;
    margin-bottom: 8px;
`;

interface SwitchProps {
    boardType: string;
    editable: boolean;
}
const Switch = styled.div<SwitchProps>`
    position: relative;
    display: inline-block;
    width: 80px;
    height: 26px;
    padding: 6px 25px 6px 12px;
    font-size: 12px;
    margin-top: 6px;
    border: 1px solid #cacaca;
    border-radius: 60px;
    line-height: 1;
    background: url(${ArrowDown}) center right 12px no-repeat;
    background-size: 14px;
    margin-right: 6px;
    :hover {
        cursor: not-allowed;
    }
    ${({ boardType, editable }) =>
        boardType === "onBoard" && editable === true
            ? css`
                  border: 1px solid #cacaca;
                  background-color: ${({ theme }) => theme.colors.white};
                  :hover {
                      cursor: pointer;
                  }
              `
            : css`
                  border: 1px solid #eee;
                  background-color: #eee;
                  color: #666;
                  pointer-events: none;
                  :hover {
                      cursor: not-allowed;
                  }
              `};
    option {
        display: none;
    }
    ${media.tablet} {
        option {
            display: none;
        }
    }
`;
interface OptionListProps {
    boardSwitch: number;
    optionListOn: boolean;
}
const OptionList = styled.div<OptionListProps>`
    opacity: ${({ optionListOn }) => (optionListOn === true ? 1 : 0)};
    position: absolute;
    width: 80px;
    height: 68px;
    top: 6px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.18);
    border-radius: 13px;
    display: flex;
    align-items: center;
    z-index: ${({ optionListOn }) => (optionListOn === true ? 10 : -1)};
    li {
        padding: 9px 20px;
        width: 80px;
        :hover {
            background-color: #f7f7f7;
            cursor: pointer;
        }
        background: url(${SelectCheck}) center left 4px no-repeat;
        :nth-child(1) {
            ${({ boardSwitch }) =>
        boardSwitch === 1
            ? `background-image: url(${SelectCheck});
                    
                    `
            : `background-image: none`};
        }
        :nth-child(2) {
            ${({ boardSwitch }) =>
        boardSwitch === 1
            ? `background-image: none`
            : `background-image: url(${SelectCheck});
                    `};
        }
    }
`;

// 버스 운행관제 - 운행 목록 - 타요/안타요 스위치
export const BoardSwitch: React.FC<BoardSwitchProps> = ({ schedule, boardType }) => {
    const { editable } = useContext(BusServiceContext);
    const [optionListOn, setOptionListOn] = useState(false);

    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false);
    const [targetBoardSwitch, setTargetBoardSwitch] = useState<number>(1);

    const isEditable = (() => {
        // 스케쥴 - 학생- 타 학원 학생 여부
        const scheduleEditableByStudent: boolean = !!(schedule?.student?.isOtherAcademyStudent) === false;
        // B2C학생일 경우 수정불가
        if (schedule.isB2CUser) return false;
        // 버스 서비스 콘텍스트의 editable과 schedule.student.isOtherAcademyStudent가 모두 false일 경우 수정가능
        return editable && scheduleEditableByStudent;
    })();
    

    const popup2InMap: any = document.querySelector(".leaflet-popup-content .popup2-container");

    if (popup2InMap) {
        popup2InMap.style.width = "0";
        popup2InMap.style.height = "0";
        const popup2Background: any = document.querySelector(".leaflet-popup-content .popup2-background");
        popup2Background.style.background = "none";
    }

    const activeCloseOption = (e: any) => {
        const optionList = document.querySelector(".option-list");
        if (e.target !== optionList) {
            setOptionListOn(false);
            document.removeEventListener("click", activeCloseOption, false);
        }
    };

    return (
        <BoardSwitchWrap>
            <Switch
                onClick={e => {
                    e.preventDefault();
                    setOptionListOn(true);
                    document.addEventListener("click", activeCloseOption);
                }}
                boardType={boardType}
                editable={isEditable}
            >
                {schedule.boardSwitch === 1 ? "타요" : "안타요"}
            </Switch>
            <OptionList boardSwitch={schedule.boardSwitch} optionListOn={optionListOn}>
                <ul className="option-list">
                    <li
                        onClick={e => {
                            e.preventDefault();
                            console.log(schedule.boardSwitch);
                            if (schedule.boardSwitch !== 1) {
                                setTargetBoardSwitch(1);
                                setConfirmPopUp(true);
                            }
                        }}
                    >
                        타요
                    </li>
                    <li
                        onClick={e => {
                            e.preventDefault();
                            if (schedule.boardSwitch === 1) {
                                setTargetBoardSwitch(0);
                                setConfirmPopUp(true);
                            }
                        }}
                    >
                        안타요
                    </li>
                </ul>
            </OptionList>
            <PopUp2
                open={confirmPopUp}
                handler={setConfirmPopUp}
                contents={
                    <ConfirmBoardSwitch schedule={schedule} boardSwitch={targetBoardSwitch} handler={setConfirmPopUp} />
                }
            />
        </BoardSwitchWrap>
    );
};
