import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {flexRow} from "styles/CommonStyle";
import {media} from "styles/media";
import Select from "components/atoms/categorySelect/CategorySelect";
import TextInput from "components/atoms/input/CategoryTextInput";
import {Optional} from "type/Common";
import {isEmptyString, isNil} from "components/ValidataionUtil";
import TextArea from "components/atoms/textarea/TextArea";
import {deleteFileApi, getBoardRequestCategories, getFileInfo, uploadFileApi} from "service/board/Board";
import {CBoardCategory, CategoryOptions} from "model/board";
import {FileInput, UploadButton, UploadFileResponse} from "../boardDetail/BoardDetailReplies";

// select mock data
const options = [
    null,
    {
        idx: 1,
        exposure: "학생 관련",
        value: 1,
    },
    {idx: 2, exposure: "차량 관련", value: 2},
    {idx: 3, exposure: "노선 관련", value: 3},
];

interface UserInfo {
    id: number;
    email: string;
    name: string;
    phone: string;
    role: string;
    enrolledAt: string;
    academy: {
        id: number;
        name: string;
    };
}

const academyId = localStorage.getItem("academyId");
const userInfoString = localStorage.getItem("userInfo");
const userInfo: UserInfo = JSON.parse(userInfoString || "{}");

type RegisterBoardProps = {
    onClose: () => void;
    handleRegister: () => void;
    updateNewRequestData: (arg: any) => void;
};

type UpladingFiles = string[];

function RegisterBoard(props: RegisterBoardProps) {
    const contentRef = useRef(null);
    const inputFileRef = useRef(null);
    const [boardCategories, setBoardCategories] = useState<CBoardCategory[]>([]);
    const [secondOptions, setSecondOptions] = useState<CategoryOptions[]>([]);
    const [firstSelect, setFirstSelect] = useState<Optional<string>>(null);
    const [secondSelect, setSecondSelect] = useState<Optional<string>>(null);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [registerButtonStatus, setRegisterButtonStatus] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null); // State for selected file
    const [uploadFileResponse, setUploadFileResponse] = useState<string>(""); // State for selected file
    const [uploadingFiles, setUploadingFiles] = useState<UpladingFiles[]>([]);

    const TitleContainerStyle = useMemo(() => {
        return {
            borderRadius: "6px",
            border: "1px solid rgba(208, 208, 208, 0.60)",
            background: "#EFEFEF",
            height: "38px",
        };
    }, []);

    const getBoardCategories = useCallback(() => {
        getBoardRequestCategories()
            .then((res) => {
                const categories = res.boardRequestCategories;
                console.log("categories", categories);
                setBoardCategories(categories);
            })
            .catch((error) => {
                throw new Error(`getBoardCategories() failed. error : ${error}`);
            });
    }, []);

    const onChangeFirstSelectValue = useCallback(
        (v: string) => {
            setFirstSelect(v);
            console.log(v);
            console.log(boardCategories);

            const filteredBoardCategories = boardCategories.filter((b) => b.parentId === Number(v));

            let secondOptions = [null];
            filteredBoardCategories.forEach((boardCategory, index) => {
                const option = {
                    idx: index + 1,
                    exposure: boardCategory.name,
                    value: boardCategory.id,
                };
                secondOptions.push(option);
            });
            console.log(secondOptions);
            setSecondOptions(secondOptions);
        },
        [boardCategories]
    );

    const onChangeSecondSelectValue = useCallback((v: string) => {
        setSecondSelect(v);
        console.log(v);
    }, []);

    const onChangeTitle = useCallback(
        (v: string) => {
            setTitle(v);
            console.log(firstSelect, secondSelect);
        },
        [firstSelect, secondSelect]
    );

    const onChangeContent = useCallback(
        (v: string) => {
            contentRef.current.value = v;
            setContent(v);
        },
        [setContent]
    );

    const createRequest = () => {
        console.log(uploadingFiles);

        const uploadingFilesText = uploadingFiles.length > 1 ? uploadingFiles.join("\n") : uploadingFiles;
        const combindedContent = content + "\n\n" + uploadingFilesText;
        console.log(combindedContent);

        const newRequestData = {
            academyId: Number(academyId),
            requestCategoryId: Number(secondSelect),
            writerId: userInfo.id,
            title: title,
            content: combindedContent,
        };

        console.log(newRequestData);
        props.updateNewRequestData(newRequestData);
        props.handleRegister();
    };

    useEffect(() => {
        if (!isNil(firstSelect) && !isNil(secondSelect) && !isEmptyString(title)) {
            setRegisterButtonStatus(true);
        }
        if (isNil(firstSelect) || isNil(secondSelect) || isEmptyString(title)) {
            setRegisterButtonStatus(false);
        }
    }, [firstSelect, secondSelect, title, content]);

    useEffect(() => {
        getBoardCategories();
    }, [getBoardCategories]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0]; // 선택한 파일
        const limitSizeMB = 10;
        const maxSize = limitSizeMB * 1024 * 1024; // 5MB
        if (file && file.size > maxSize) {
            setSelectedFile(null);
            setUploadFileResponse(`파일 크기는 ${limitSizeMB}MB를 초과할 수 없습니다.`);
            event.target.value = "";
            return;
        } else {
            setUploadFileResponse(``);
        }

        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const uploadFile = useCallback(async () => {
        if (!selectedFile) {
            alert("파일을 선택해주세요.");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const res = await uploadFileApi(formData);
            inputFileRef.current.value = "";

            const urlParts = res.url.split("/");
            const fileName = urlParts[urlParts.length - 1]; // URL의 마지막 부분이 파일 이름
            let fileText;

            if (res.url.match(/\.(jpeg|jpg|png|gif|bmp)$/i)) {
                fileText = `<a href="${res.url}" title="Click to download" download><img src="${res.url}" alt="${fileName}" style="max-width: auto; height: 200px;" /></a>`;
            } else {
                fileText = `<a href="${res.url}" title="Click to download" download>${fileName}</a>`;
            }

            console.log("Uploaded file content:", fileText);

            // 기존 content에 새 fileText 추가
            // setContent((prevContent) => (prevContent ? prevContent + "\n" + fileText : fileText));
            setUploadingFiles((prevUploadingFiles) => [...prevUploadingFiles, fileText]);
            // setUploadFileResponse("파일이 성공적으로 업로드되었습니다.");
        } catch (error) {
            setUploadFileResponse("파일 업로드에 실패했습니다. 다시 시도해주세요.");
            console.error("File upload failed:", error);
        }

        console.log(content);
    }, [selectedFile, content]);

    const fileChips = useMemo(() => {
        console.log(uploadingFiles);
        return uploadingFiles.map((file, index) => (
            <FileChip key={index}>
                <File dangerouslySetInnerHTML={{__html: file}}></File>
                <DeleteButton
                    onClick={async () => {
                        setUploadingFiles(uploadingFiles.filter((f) => f !== file));
                        const fileInfo = await getFileInfo(file);
                        const response = await deleteFileApi(fileInfo.fileKey);
                        console.log(response);
                    }}
                >
                    ✕
                </DeleteButton>
            </FileChip>
        ));
    }, [uploadingFiles]);

    return (
        <Container>
            <Category>카테고리 선택</Category>
            <SelectWrapper>
                <Select options={options} placeholder="1차 분류 카테고리" onChange={onChangeFirstSelectValue} height={38} border={true} fontSize={12} borderRadius={6} selectedValue={firstSelect} />
                <Select
                    options={secondOptions}
                    placeholder="2차 분류 카테고리"
                    onChange={onChangeSecondSelectValue}
                    fontSize={12}
                    height={38}
                    editable={isNil(firstSelect) ? true : false}
                    border={true}
                    borderRadius={6}
                    selectedValue={secondSelect}
                />
            </SelectWrapper>
            <Category>제목</Category>
            <StyledTextInput onChange={onChangeTitle} readOnly={false} required={false} placeholder="홍길동(010-1234-5678) 간략 요청 제목(필수)" containerStyle={TitleContainerStyle} />
            <Category style={{marginTop: 16}}>본문</Category>
            <TextArea onChange={onChangeContent} readOnly={false} required={false} placeholder="상세 요청 작성(선택)" ref={contentRef} value={content} />
            <InputContainer>
                <FileInput type="file" onChange={handleFileChange} ref={inputFileRef} />
                <UploadButton onClick={uploadFile}>파일 등록</UploadButton>
                <UploadFileResponse id="response" hasResponse={uploadFileResponse}>
                    {uploadFileResponse}
                </UploadFileResponse>
            </InputContainer>
            <FileList>{fileChips}</FileList>
            <ButtonWrapper>
                <CancelButton onClick={props.onClose}>취소</CancelButton>
                <RegisterButton status={registerButtonStatus} onClick={registerButtonStatus ? createRequest : () => {}}>
                    등록
                </RegisterButton>
            </ButtonWrapper>
        </Container>
    );
}

export default RegisterBoard;

const Container = styled.div`
    width: 100%;
    ${media.tablet} {
        width: 388px;
    }
`;

const Category = styled.div`
    color: #174490;
    font-family: NanumSquareRound;
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 8px;
`;

const SelectWrapper = styled.div`
    ${flexRow};
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
`;

const StyledTextInput = styled(TextInput)`
    width: 100%;
    cursor: pointer;

    :focus-within {
        outline: 1px solid #ffcd00;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
`;

const ButtonWrapper = styled.div`
    ${flexRow};
    align-items: center;
    margin-top: 30px;
    float: right;
    column-gap: 4px;
`;

export const CancelButton = styled.div`
    border-radius: 30px;
    background: #efefef;
    width: 70px;
    height: 36px;
    color: #000;
    line-height: 16px;
    font-family: NanumSquareRound;
    font-size: 14px;
    font-weight: 800;
    padding: 10px 22px;
    cursor: pointer;
`;

type ButtonStatus = {
    status: boolean;
};

export const RegisterButton = styled(CancelButton)<ButtonStatus>`
    background: ${(props) => (props.status ? "#ffcd00" : "#fff1b3")};
    color: ${(props) => (props.status ? "#000" : "#cccccc")};
    cursor: ${(props) => (props.status ? "pointer" : "not-allowed")};
`;

const InputContainer = styled.div`
    padding: 10px 0px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;
    ${media.tablet} {
        padding: 10px 0px;
    }
`;

const FileList = styled.ul`
    max-height: 150px;
    overflow: auto;
    &li {
        padding: 5px 0;
    }
`;

const File = styled.div`
    & img {
        max-height: 100px;
    }
`;

const FileChip = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #efefef;
    padding: 8px;
    border-radius: 4px;
    margin: 0 0 4px 0;
`;

const DeleteButton = styled.button`
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 4px;
    width: 20px;
`;
