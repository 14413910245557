import React from "react";
import styled from "styled-components";
import { BoardTitle, BoardBox, StudentList, Student, NoShow, Name, DisabledEditStudent } from "./OnBoard";
import _ from "lodash";
import { BoardSwitch } from "./BoardSwitch";
import { FiMenu } from "react-icons/fi";
import { Draggable } from "react-beautiful-dnd";

interface OffBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
    scheduleEdit: boolean; // DND 여부임..
    showNickName: boolean;
}

const OffBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.blue};
    }
`;

const EditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;

// 학생 이름 표시 로직(닉네임, B2C, 다른 학원 여부)
const renderName = (schedule: any, isOurStudent: boolean, showNickName: boolean) => {
    const studentName = schedule.student?.name;
    const nickNamePart =
        schedule.student?.nickName && schedule.student?.nickName.trim() !== ""
            ? ` - ${schedule.student?.nickName}`
            : "";
    const nameText = (() => {
        if (showNickName) {
            if (!nickNamePart) return studentName;
            return `${studentName}${nickNamePart}`;
        }
        return studentName;
    })();

    if (schedule.isB2CUser) {
        return (
            <p>
                <span>
                    {`${nameText}(B2C)`}
                </span>
            </p>
        );
    }
    if (isOurStudent) {
        return (
            <Name
                to={`/students/${schedule.student?.id}`}
                title="학생 페이지로 이동"
                target="blank"
            >
                {nameText}
            </Name>
        );
    }
    return (
        <p>
            <span>
                {nameText}
            </span>
        </p>
    );
};


// 탑승 상태 표시 로직
const renderBoardSwitch = (schedule: any) => {
    if (schedule.boardSwitch !== 1) return null;
    if (schedule.status === 3) return <NoShow>미승차</NoShow>;
    if (schedule.status === 4) return " 미탑승예약";
    if (schedule.status === 5) return <NoShow>학원 복귀</NoShow>;
    return ` ${schedule.timeOffBoard}`;
};


const renderDefaultView = (schedule: any, isOurStudent: boolean, showNickName: boolean) => (
    <div>
        {renderName(schedule, isOurStudent, showNickName)}
        <div className="board-switch">
            <BoardSwitch schedule={schedule} boardType="offBoard" />
            {renderBoardSwitch(schedule)}
        </div>
    </div>
);

const renderDragView = (schedule: any, isOurStudent: boolean, index: any) => {
    // B2C 학생은 드래그 불가
    if (schedule.isB2CUser || !isOurStudent) {
        return (
            <DisabledEditStudent>
                <FiMenu size="12" color="#999" />
                <span>{`${schedule.student.name}(B2C)`}</span>
            </DisabledEditStudent>
        );
    }

    return (
        <Draggable
            key={`outbound-draggable-${schedule.student.id}`}
            draggableId={`outbound-${schedule.student.id}`}
            data-studentid={schedule.student.id}
            index={index}
        >
            {(provided) => (
                <EditStudent
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                >
                    <FiMenu size="12" color="#999" />
                    <span>{schedule.student.name}</span>
                </EditStudent>
            )}
        </Draggable>
    );
};

// 버스 운행관제 - 안타요(?)

export const OffBoard: React.FC<OffBoardProps> = ({ stationId, schedules, studentId, scheduleEdit, showNickName }) => {
    let offBoardCounts = 0;

    if (!_.isEmpty(schedules)) {
        schedules.forEach((schedule: any) => {
            if (stationId === Number(schedule.takeOffStation?.id) && schedule.status !== 4) {
                offBoardCounts += 1;
            }
        });
    }

    if (offBoardCounts === 0) return <></>;

    return (
        <BoardBox>
            <OffBoardTitle>
                <span>하차</span>
            </OffBoardTitle>
            <StudentList>
                {schedules.map((schedule: any, index: number) => {
                    // 다른 학원 학생 여부 판단
                    const isOurStudent: boolean = !(schedule?.student?.isOtherAcademyStudent);

                    // 특정 조건이 만족하지 않으면 렌더링하지 않음
                    const studentInfoRenderCondition =
                        stationId === Number(schedule.takeOffStation?.id) &&
                        schedule.status !== 4;

                    if (!studentInfoRenderCondition) return <React.Fragment key={index} />;

                    return (
                        <Student
                            key={schedule.scheduleId}
                            thisStudentId={schedule.student?.id}
                            studentId={studentId}>
                            {!scheduleEdit ? renderDefaultView(schedule, isOurStudent, showNickName) : renderDragView(schedule, isOurStudent, index)}
                        </Student>
                    );
                })}
            </StudentList>
        </BoardBox>
    );
};
