import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BoardTitle, BoardBox, StudentList, Student, NoShow } from "./OnBoardMap";
import _ from "lodash";

interface OffBoardMapProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
}

const OffBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.blue};
    }
`;

// 학생 정보 - 학생 이름 관련 렌더링
const renderName = (schedule: any, isOurStudent: boolean) => {
    if (schedule.isB2CUser) return <span>{schedule.student?.name}(B2C)</span>;
    if (isOurStudent) {
        return (
            <Link to={`/students/${schedule.student?.id}`} title="학생 페이지로 이동" target="blank">
                {schedule.student?.name}
            </Link>
        );
    }
    return <span>{schedule.student?.name}</span>;
};

// 학생 정보 - 탑승 여부 관련 렌더링
const renderBoardSwitch = (schedule: any) => {
    if (schedule.boardSwitch !== 1) return null;
    if (schedule.status === 3) return <NoShow>미승차</NoShow>;
    if (schedule.status === 4) return " 미탑승예약";
    return ` ${schedule.timeOffBoard}`;
};

export const OffBoardMap: React.FC<OffBoardMapProps> = ({ stationId, schedules, studentId }) => {
    let offBoardCounts = 0;

    if (!_.isEmpty(schedules)) {
        schedules.forEach((schedule: any) => {
            if (stationId === Number(schedule.takeOffStation?.id) && schedule.status !== 4) {
                offBoardCounts += 1;
            }
        });
    }

    // 하차할 학생이 없을 경우 빈 태그 반환
    if (offBoardCounts === 0) return <></>;

    return (
        <BoardBox>
            <OffBoardTitle>
                <span>하차</span>
            </OffBoardTitle>
            <StudentList>
                {schedules.map((schedule: any, index: number) => {
                    // 현재 선택된 학생이 다른 학원 학생인지 여부
                    const isOurStudent: boolean = !!(schedule?.student?.isOtherAcademyStudent) === false;
                    // 넘어온 stationId와 schedule.takeOffStation.id가 같고, status가 4가 아닐 때만 렌더링
                    const studentInfoRenderCondition = ((stationId === Number(schedule.takeOffStation?.id)) && (schedule.status !== 4));

                    // 렌더링 조건이 아닐 경우 빈 태그 반환
                    if (!studentInfoRenderCondition) return <React.Fragment key={index} />;


                    // 학생 정보 렌더링
                    return (
                        <Student key={schedule.student?.id} thisStudentId={schedule.student?.id} studentId={studentId}>
                            {renderName(schedule, isOurStudent)}
                            {schedule.boardSwitch === 1 ? " (타요)" : " (안타요)"}
                            {renderBoardSwitch(schedule)}
                        </Student>
                    );
                })}
            </StudentList>
        </BoardBox>
    );
};
