import React, { useState } from "react";
import styled from "styled-components";
import { BoardTitle, BoardBox, StudentList, Student, Name } from "./OnBoard";
import _ from "lodash";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";

interface OffBoardProps {
    stationId: number;
    studentId: number | undefined;
    detail?: any;
}

const renderName = (student: any, isOurStudent: boolean) => {
    const nickNamePart = student?.nickName && student?.nickName.trim() !== "" && student?.nickName !== "-"
        ? `${student?.nickName} / `
        : "";
    const nameText = `${nickNamePart}${student?.name}(${student.parentPhone.slice(-4)})`;

    if (isOurStudent) {
        return (
            <Name
                to={`/students/${student?.id}`}
                title={"학생 페이지로 이동"}
                target={"blank"}
            >
                {nameText}
            </Name>
        );
    }
    return <p>{nameText}</p>;
};

export const OffBoard: React.FC<OffBoardProps> = ({
    stationId,
    studentId,
    detail,
}) => {
    const [showPeriod, setShowPeriod] = useState(false);
    const [studentName, setStudentName] = useState<string>("");
    let offBoardCounts = 0;

    if (!_.isEmpty(detail)) {
        detail.forEach((student: any) => {
            if (stationId === Number(student?.takeOffStationId)) {
                offBoardCounts += 1;
            }
        });
    }

    const handleMouseEnter = (name: string) => () => {
        setShowPeriod(true);
        setStudentName(name);
    };
    
    const handleMouseLeave = () => {
        setShowPeriod(false);
        setStudentName("");
    };

    if (offBoardCounts === 0) return <></>;

    return (
        <BoardBox>
            <OffBoardTitle>
                <span>하차</span>
            </OffBoardTitle>
            <StudentList>
                {detail?.map((student: any, index: any) => {
                    // 다른 학원 학생 여부 판단
                    const isOurStudent: boolean = !(student?.isOtherAcademyStudent);
                    // 특정 조건이 만족하지 않으면 렌더링하지 않음
                    const studentInfoRenderCondition = stationId === Number(student.takeOffStationId);

                    if (!studentInfoRenderCondition) return <React.Fragment key={index} />;

                    return (
                        <Student
                            key={student.id}
                            thisStudentId={student?.id}
                            studentId={studentId}
                            onMouseEnter={handleMouseEnter(student.name)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {renderName(student, isOurStudent)}
                            <div style={{ position: "relative" }}>
                                {showPeriod && studentName === student?.name && (
                                    <>
                                        <ToolTipBoxVector />
                                        <ToolTip>
                                            <PeriodSpan>승차 일자 :</PeriodSpan>
                                            <br />
                                            {student?.tickets?.map((item: any, idx: number) => (
                                                <PeriodSpan key={idx}>{item}</PeriodSpan>
                                            ))}
                                        </ToolTip>
                                    </>
                                )}
                            </div>
                        </Student>
                    );
                })}
            </StudentList>
        </BoardBox>
    );
};

const OffBoardTitle = styled(BoardTitle)`
    background-color: ${({ theme }) => theme.colors.blue};
    span {
    }
`;

const ToolTip = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    flex-direction: column;
    position: absolute;
    width: 163px;
    padding: 12px;
    border-radius: 4px;
    background: #333;
    top: 8px;
    left: -35px;
    z-index: 9;
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 3px;
    left: 40px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;

const PeriodSpan = styled.span`
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
`;
