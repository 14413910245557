import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";

interface OnBoardProps {
    stationId: number;
    studentId: number | undefined;
    detail?: any;
}

const renderName = (student: any, isOurStudent: boolean) => {
    const nickNamePart = student?.nickName && student?.nickName.trim() !== "" && student?.nickName !== "-"
        ? `${student?.nickName} / `
        : "";
    const nameText = `${nickNamePart}${student?.name}(${student.parentPhone.slice(-4)})`;

    if (isOurStudent) {
        return (
            <Name
                to={`/students/${student?.id}`}
                title={"학생 페이지로 이동"}
                target={"blank"}
            >
                {nameText}
            </Name>
        );
    }
    return <p>{nameText}</p>;
};

export const OnBoard: React.FC<OnBoardProps> = ({
    stationId,
    studentId,
    detail,
}) => {
    const [showPeriod, setShowPeriod] = useState(false);
    const [studentName, setStudentName] = useState<string>("");
    let onBoardCounts = 0;

    if (!_.isEmpty(detail)) {
        detail.forEach((student: any) => {
            if (stationId === Number(student?.pickUpStationId)) {
                onBoardCounts += 1;
            }
        });
    }

    const handleMouseEnter = (name: string) => () => {
        setShowPeriod(true);
        setStudentName(name);
    };

    const handleMouseLeave = () => {
        setShowPeriod(false);
        setStudentName("");
    };

    if (onBoardCounts === 0) return <></>;

    return (
        <BoardBox>
            <BoardTitle>
                <span>승차</span>
            </BoardTitle>
            <StudentList>
                {detail?.map((student: any, index: any) => {
                    // 다른 학원 학생 여부 판단
                    const isOurStudent: boolean = !(student?.isOtherAcademyStudent);
                    // 특정 조건이 만족하지 않으면 렌더링하지 않음
                    const studentInfoRenderCondition = stationId === Number(student.pickUpStationId);

                    if (!studentInfoRenderCondition) return <React.Fragment key={index} />;

                    return (
                        <Student
                            key={student.id}
                            thisStudentId={student?.id}
                            studentId={studentId}
                            onMouseEnter={handleMouseEnter(student.name)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {renderName(student, isOurStudent)}
                            <div style={{ position: "relative" }}>
                                {showPeriod && studentName === student?.name && (
                                    <>
                                        <ToolTipBoxVector />
                                        <ToolTip>
                                            <PeriodSpan>승차 일자 :</PeriodSpan>
                                            <br />
                                            {student?.tickets?.map((item: any, idx: number) => (
                                                <PeriodSpan key={idx}>{item}</PeriodSpan>
                                            ))}
                                        </ToolTip>
                                    </>
                                )}
                            </div>
                        </Student>
                    );
                })}
            </StudentList>
        </BoardBox>
    );
};

export const BoardBox = styled.div`
    display: flex;
`;

export const Name = styled(Link)`
    line-height: 18px;
    font-size: 12px;
    font-weight: 700;
`;

export const BoardTitle = styled.div`
    margin-right: 12px;
    min-width: 36px;
    max-width: 36px;
    height: 18px;
    padding: 4px 7px 4px 9px;
    background-color: ${({ theme }) => theme.colors.green};
    border-radius: 30px;
    display: flex;
    align-items: center;
    span {
        min-width: 20px;
        line-height: 18px;
        display: inline-flex;
        color: #fff;
        font-weight: 800;
        font-size: 10px;
    }
`;
export const StudentList = styled.ul`
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const DisabledEditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    background-color: #e7e7e7;
    opacity: 0.6;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;

const ToolTip = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    flex-direction: column;
    position: absolute;
    width: 163px;
    padding: 12px;
    border-radius: 4px;
    background: #333;
    top: 8px;
    left: -35px;
    z-index: 9;
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 3px;
    left: 40px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;

const PeriodSpan = styled.span`
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
`;

interface StudentProps {
    thisStudentId: number | undefined;
    studentId: number | undefined;
}

export const Student = styled.li<StudentProps>`
    line-height: 18px;
    margin-bottom: 6px;

    ${({ studentId, thisStudentId }) =>
        studentId &&
        Number(thisStudentId) !== studentId &&
        css`
            color: #dedede;
            a,
            span {
                color: #dedede !important;
            }
        `};
    p {
        display: block;
        line-height: 1.8;
    }
`;

export const NoShow = styled.span`
    color: ${({ theme }) => theme.colors.orange};
    margin-left: 4px;
`;
