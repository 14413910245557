import React from "react";
import styled from "styled-components";
import { BoardTitle, BoardBox, StudentList, Student, NoShow } from "./OnBoard";
import { Link } from "react-router-dom";
import _ from "lodash";

interface WillNotBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
    scheduleEdit: boolean;
    showNickName: boolean;
}

const WillNotBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.orange};
    }
`;


// 학생 정보 - 학생 이름 관련 렌더링
const renderName = (schedule: any, isOurStudent: boolean, showNickName: boolean) => {
    const studentName = schedule.student?.name;
    const nickNamePart =
        schedule.student?.nickName && schedule.student?.nickName.trim() !== ""
            ? ` - ${schedule.student?.nickName}`
            : "";
    const nameText = (() => {
        if (showNickName) {
            if (!nickNamePart) return studentName;
            return `${studentName}${nickNamePart}`;
        }
        return studentName;
    })();

    if (schedule.isB2CUser) return <p>{nameText}(B2C)</p>;
    if (isOurStudent) {
        return (
            <Link to={`/students/${schedule.student?.id}`} title="학생 페이지로 이동" target="blank">
                {nameText}
            </Link>
        );
    }
    return <p>{nameText}</p>;
};

// 탑승 정보 렌더링
const renderBoardSwitch = (schedule: any) => {
    if (schedule.status === 0) return " 탑승 대기";
    if (schedule.status === 3) return <NoShow>미승차</NoShow>;
    if (schedule.status === 4) return " 미탑승 예약";
    if (schedule.status === 5) return <NoShow>학원 복귀</NoShow>;
    return ` ${schedule.timeOnBoard}`;
}

export const WillNotBoard: React.FC<WillNotBoardProps> = ({
    stationId,
    schedules,
    studentId,
    scheduleEdit,
    showNickName
}) => {
    let WillNotBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (
                (stationId === Number(schedule.pickUpStation?.id) && schedule.status === 4) ||
                (stationId === Number(schedule.takeOffStation?.id) && schedule.status === 4)
            ) {
                WillNotBoardCounts = WillNotBoardCounts + 1;
            }
            return null;
        });
    }

    if (WillNotBoardCounts === 0) return <></>;

    return (
        <BoardBox>
            <WillNotBoardTitle>
                <span>미탑승</span>
            </WillNotBoardTitle>
            <StudentList>
                {schedules.map((schedule: any, index: number) => {
                    // 현재 선택된 학생이 다른 학원 학생인지 여부
                    const isOurStudent: boolean = !!(schedule?.student?.isOtherAcademyStudent) === false;
                    // 넘어온 stationId와 schedule.{승하차}.id가 같고, status가 4일 때만 렌더링
                    const studentInfoRenderCondition =
                        (stationId === Number(schedule.pickUpStation?.id) && schedule.status === 4) ||
                        (stationId === Number(schedule.takeOffStation?.id) && schedule.status === 4)

                    // 렌더링 조건이 아닐 경우 빈 태그 반환
                    if (!studentInfoRenderCondition) return <React.Fragment key={index} />;

                    // 학생 정보 렌더링
                    return (
                        <Student
                            key={schedule.student?.id}
                            thisStudentId={schedule.student?.id}
                            studentId={studentId}
                        >
                            {renderName(schedule, isOurStudent, showNickName)}
                            {renderBoardSwitch(schedule)}
                        </Student>
                    );
                })}
            </StudentList>
        </BoardBox>
    );
};
